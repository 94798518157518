import Modal from '@/components/Modal/modal';
import { MessageChunk, ResponseSource } from '@/models/thread';
import { FC } from 'react';
import { createPortal } from 'react-dom';
import HTMLContent from '../HTMLContent';
import styles from './citations-modal.module.scss';
import { truncateText } from '@/utils/functions';
import CitationIndicator from '../../CitationIndicator';

interface CitationModalProps {
  chunk: MessageChunk;
  sources?: ResponseSource[];
  onClose: () => void;
}

const CitationModal: FC<CitationModalProps> = ({ chunk, sources, onClose }) => {
  if (
    !chunk?.citation.length ||
    !Array.isArray(sources) ||
    sources.length === 0
  )
    return null;

  return createPortal(
    <Modal isOpen={typeof chunk !== 'undefined'} onClose={onClose}>
      <div className={styles['citations-modal']}>
        <strong>Statement:</strong>
        <p>
          <HTMLContent text={chunk.text} />
        </p>
        <hr />
        <strong>Sources:</strong>
        <ul>
          {chunk.citation.map(({ source_id, details }) => {
            const source = sources[source_id - 1];

            if (!source) {
              return null;
            }

            return details.map(({ content, relevance }, j) => (
              <li key={j}>
                <p>{relevance}:</p>

                <blockquote>
                  {content}

                  <footer>
                    <CitationIndicator id={source_id} /> —{' '}
                    <cite>
                      <a
                        href={source.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {truncateText(source.title, 50)}
                      </a>
                    </cite>
                  </footer>
                </blockquote>
              </li>
            ));
          })}
        </ul>
      </div>
    </Modal>,
    document.body
  );
};

export default CitationModal;
