import useSession from '@/hooks/useSession';
import useBlock from '@/pages/Thread/hooks/useBlock';
import useThread from '@/pages/Thread/hooks/useThread';
import styles from './citation.module.scss';
import { FC } from 'react';

interface CitationIndicatorProps {
  id: number;
  onClick?: () => void;
  enaled?: boolean;
}

const CitationIndicator: FC<CitationIndicatorProps> = ({
  id,
  onClick = undefined,
}) => {
  const { pushUserEvent } = useSession();
  const { status } = useThread();
  const { isLast } = useBlock();

  const className = styles['citation'];
  return typeof onClick === 'function' ? (
    <button
      className={className}
      onMouseEnter={(elem) => {
        const parent = (elem.target as HTMLElement).parentElement;

        if (parent) {
          parent.style.color = 'var(--brand-light)';
        }
      }}
      onMouseLeave={(elem) => {
        (elem.target as HTMLElement).parentElement!.style.color = '';
      }}
      onClick={() => {
        onClick();
        pushUserEvent('click_citation');
      }}
      disabled={isLast && status !== 'READY'}
    >
      {id}
    </button>
  ) : (
    <span className={className}>{id}</span>
  );
};

export default CitationIndicator;
