import { FC } from 'react';

interface HTMLContentProps {
  text: string;
}

const HTMLContent: FC<HTMLContentProps> = ({ text = '' }) => {
  return text
    .split(/(\n)/)
    .map((part, i) => <span key={i}>{part === '\n' ? <br /> : part}</span>);
};

export default HTMLContent;
