import { useState } from 'react';
import styles from './sign-up.module.scss';
import Button from '@/components/Button';
import Form from '@/components/Form';
import InputWrapper from '@/components/Form/InputWrapper';
import HomeLayout from '@/layouts/HomeLayout';
import { Link } from 'react-router-dom';
import SEOHelmet from '@/components/SEOHelmet';
import { gaPushEvent } from '@/utils/analytics';
import EmailLink from '@/components/EmailLink';
import { ErrorException } from '@/models/error';
import NewPasswordFields from '@/components/NewPasswordFields';
import { signUp } from 'aws-amplify/auth';
import CheckboxInput from '@/components/CheckboxInput';

const SignUp = () => {
  const [submitted, setSubmitted] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [email, setEmail] = useState('');

  const subscribeToNewsletter = async (
    formData: FormData
  ): Promise<boolean> => {
    const newsletterURL = import.meta.env.VITE_NEWSLETTER_URL;

    if (!newsletterURL) {
      console.error('Newsletter URL not found');
      return true;
    }

    try {
      const response = await fetch(newsletterURL, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();

      return result.success;
    } catch (err) {
      gaPushEvent('mailerlite_error');
      console.error(err);

      return false;
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const mlFormData = new FormData(event.currentTarget);
    mlFormData.delete('password');
    mlFormData.delete('confirm_password');
    const signUpEmail = formData.get('fields[email]') as string;
    setEmail(signUpEmail);
    setFetching(true);
    try {
      const addedToNewsletter = await subscribeToNewsletter(mlFormData);

      if (addedToNewsletter) {
        gaPushEvent('sign_up_newsletter');

        await signUp({
          username: signUpEmail,
          password: formData.get('password') as string,
          options: {
            userAttributes: {
              name: formData.get('fields[name]') as string,
              email: signUpEmail,
              'custom:organization': formData.get('fields[company]') as string,
            },
          },
        });

        gaPushEvent('sign_up');
        setSubmitted(true);
      } else {
        throw new Error('Failed to subscribe');
      }
    } catch (err) {
      const error = err as ErrorException;

      let message = 'An error happened, please try again later.';
      if (
        error.name === 'UsernameExistsException' ||
        error.message.includes('Specified user is already on')
      ) {
        message =
          "Your account is already registered. Please sign in or try to recover your password if you're having any issues!";
      }
      alert(message);
    } finally {
      setFetching(false);
    }
  };

  return (
    <HomeLayout>
      <SEOHelmet title="Sign Up" description="Join GANNET" index={true} />
      <section className={styles['sign-up-wrapper']}>
        {submitted ? (
          <div
            className={styles['success-message']}
            data-testid="success-signup"
          >
            <h1>Thank you for signing up!</h1>
            <p>
              We're thrilled to have you on board. We just sent you a
              confirmation email to <strong> {email} </strong>with more details
              about GANNET and your next steps.
            </p>

            <p>
              Remember to check your spam folder if you don't see it in your
              inbox. if you have any questions, please contact us at{' '}
              <EmailLink />.
            </p>
          </div>
        ) : (
          <>
            <h1>Join GANNET</h1>
            <div id="mlb2-14507637">
              <Form onSubmit={handleSubmit}>
                <InputWrapper>
                  <input type="text" id="name" name="fields[name]" required />
                  <label htmlFor="name">Full name:</label>
                </InputWrapper>
                <InputWrapper>
                  <input
                    type="text"
                    id="organization"
                    name="fields[company]"
                    autoComplete="off"
                    required
                  />
                  <label htmlFor="organization">Organization:</label>
                </InputWrapper>
                <InputWrapper>
                  <input
                    type="email"
                    id="email"
                    name="fields[email]"
                    required
                  />
                  <label htmlFor="email">Email:</label>
                </InputWrapper>
                <NewPasswordFields />
                <CheckboxInput id="fields[get_updates][]">
                  Subscribe to receive news and updates about GANNET.
                </CheckboxInput>
                <CheckboxInput id="privacy" required>
                  I have read and agree to GANNET's{' '}
                  <Link to="/privacy" target="_blank">
                    Privacy Policy
                  </Link>{' '}
                  and{' '}
                  <Link to="/terms-of-use" target="_blank">
                    Terms of Use
                  </Link>
                </CheckboxInput>

                <input type="hidden" name="ml-submit" value="1" />
                <input type="hidden" name="anticsrf" value="true" />

                <Button
                  variation="alt"
                  disabled={fetching || submitted}
                  loading={fetching}
                  loadingText="Signing up"
                >
                  SIGN UP
                </Button>
              </Form>

              <script
                src="https://groot.mailerlite.com/js/w/webforms.min.js?v2d8fb22bb5b3677f161552cd9e774127"
                type="text/javascript"
              ></script>
              <script>
                fetch("https://assets.mailerlite.com/jsonp/871966/forms/120253926663521333/takel")
              </script>
            </div>
          </>
        )}
      </section>
    </HomeLayout>
  );
};

export default SignUp;
