import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import BrowserRoutes from './components/BrowserRoutes';
import { Amplify } from 'aws-amplify';
import { AMPLIFY_CONFIG } from '@/config/aws';
import * as Sentry from '@sentry/react';
import Hotjar from '@hotjar/browser';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import './scss/theme.scss';
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

Amplify.configure(AMPLIFY_CONFIG);

if (import.meta.env.VITE_HOTJAR_ID) {
  Hotjar.init(import.meta.env.VITE_HOTJAR_ID, 6);
}

if (import.meta.env.VITE_SENTRY_DSN) {
  const host = window.location.host;
  let env;
  if (host === 'gannet.ai') {
    env = 'production';
  } else if (host.includes('localhost')) {
    env = 'local';
  } else if (host.includes('dev')) {
    env = 'dev';
  } else {
    env = host;
  }

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: env,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRoutes />
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" />
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>
);
