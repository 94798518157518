import useSession from '@/hooks/useSession';
import {
  classnames,
  dateDiffInDays,
  getToday,
  truncateText,
} from '@/utils/functions';
import { ChangeEvent, FormEvent, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './search.module.scss';
import SEOHelmet from '@/components/SEOHelmet';
import MsgParagraph from '@/components/MsgParagraph';
import Modal from '@/components/Modal/modal';
import PricingTable from '@/components/PricingTable';
import { Plan } from '@/models/session';

const Search = () => {
  const {
    session: { threads },
  } = useSession();
  const [query, setQuery] = useState<string>('');
  const {
    session: {
      promptUsage: { plan },
    },
    pushUserEvent,
  } = useSession();
  const navigate = useNavigate();
  const allowedSearch = plan !== Plan.Free;
  const today = useRef(getToday());

  const filteredThreads = useMemo(() => {
    if (query === '') return threads;
    return threads.filter((thread) => {
      const titleMatched = thread.title.toLocaleLowerCase().includes(query);

      if (titleMatched) return true;

      const contentMatched = thread.blocks.some(
        (block) =>
          block.prompt.toLocaleLowerCase().includes(query) ||
          block.response.some((response) => {
            try {
              return response.text.toLocaleLowerCase().includes(query);
            } catch (e) {
              return false;
            }
          })
      );

      if (contentMatched) return true;

      return false;
    });
  }, [threads, query]);

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    handleSearch((formData.get('search') as string) || '');
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value);
  };

  const handleSearch = (value: string): void => {
    if (allowedSearch) setQuery(value.toLocaleLowerCase());
  };

  return (
    <div
      className={classnames(styles['search-wrapper'], {
        [styles['restricted']]: !allowedSearch,
      })}
    >
      {!allowedSearch && (
        <Modal
          title="Upgrade your plan"
          isOpen={!allowedSearch}
          onClose={() => {
            navigate('/');
          }}
          size="large"
        >
          <PricingTable />
        </Modal>
      )}
      <SEOHelmet title="Search Threads" />
      <h1>Search Threads</h1>

      {threads.length === 0 ? (
        <MsgParagraph>
          You currently have no threads.Please <Link to="/">click here</Link> to
          create your first thread on Gannet
        </MsgParagraph>
      ) : (
        <>
          <form className={styles['search-form']} onSubmit={onSubmit}>
            <input
              type="search"
              id="search"
              name="search"
              placeholder="Type to search..."
              onChange={onSearchChange}
              required
              disabled={!allowedSearch}
            />
          </form>
          <ul className={styles['search-list']}>
            {filteredThreads.map((thread) => {
              const lastMessage = thread.blocks[thread.blocks.length - 1];
              const dateDiff = dateDiffInDays(
                new Date(lastMessage.queryDate),
                today.current
              );

              return (
                <li key={thread.id}>
                  <Link
                    to={`/thread/${thread.id}`}
                    onClick={() => {
                      pushUserEvent('click_search_result');
                    }}
                  >
                    {thread.title}
                  </Link>

                  <p>
                    <strong>Last message:</strong>{' '}
                    {lastMessage.response[0]?.text
                      ? truncateText(lastMessage.response[0].text, 200)
                      : 'N/A'}
                  </p>
                  <span>
                    <strong>Last update:</strong>
                    {dateDiff > 0
                      ? `${dateDiff} day${dateDiff > 1 ? 's' : ''} ago`
                      : 'Today'}
                  </span>
                </li>
              );
            })}
            {filteredThreads.length === 0 && (
              <MsgParagraph>
                Couldn't find any mentions of <strong>"{query}"</strong> in your
                threads.
              </MsgParagraph>
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default Search;
