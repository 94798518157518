import { useRef, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Form from '@/components/Form';
import Button from '@/components/Button';
import HomeLayout from '@/layouts/HomeLayout';
import SEOHelmet from '@/components/SEOHelmet';
import { ErrorException } from '@/models/error';
import { gaPushEvent } from '@/utils/analytics';
import useSession from '@/hooks/useSession';
import InputWrapper from '@/components/Form/InputWrapper';
import { resendSignUpCode, resetPassword } from 'aws-amplify/auth';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
  const location = useLocation();

  const userRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { setSession } = useSession();

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(location.state?.email || '');

  useEffect(() => {
    if (userRef.current) userRef.current.focus();
  }, [location.state]);

  useEffect(() => {
    toast.dismiss();
  }, [user]);

  const requestPasswordReset = async () => {
    try {
      setLoading(true);
      const { nextStep } = await resetPassword({ username: user });
      if (nextStep.resetPasswordStep !== 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        throw new Error('Unexpected response');
      }
      gaPushEvent('recovery_password_attempt');
      setSession((current) => ({
        ...current,
        email: user,
      }));
      navigate('/reset-password', { state: { forgotPassword: true } });
    } catch (err) {
      const error = err as ErrorException;

      if (error.name === 'UserNotFoundException') {
        toast(
          'The specified email address is not associated with an existing account.',
          { type: 'error' }
        );
        gaPushEvent('error_forgot_password_user_not_found');
      } else if (error.name === 'InvalidParameterException') {
        try {
          await resendSignUpCode({ username: user });
          toast(
            "We've sent you a confirmation email. Please check your inbox, confirm your account, and then try to recover your password again.",
            { type: 'success', autoClose: false }
          );
          gaPushEvent('confirmation_resent_on_forgot_password');
        } catch (resendError) {
          toast(
            'An errror happened. Please contact us directly at info@gannet.ai',
            { type: 'error' }
          );
          gaPushEvent('error_resend_confirmation_on_forgot_password');
          console.error(resendError);
        }
      } else {
        toast(
          'An errror happened. Please contact us directly at info@gannet.ai',
          { type: 'error' }
        );
        gaPushEvent('error_forgot_password_unknown');
      }
      setLoading(false);
    }
  };

  return (
    <HomeLayout>
      <SEOHelmet
        title="Reset Password"
        description="Reset Password for GANNET"
      />
      <Form onSubmit={() => requestPasswordReset()}>
        <InputWrapper>
          <input
            type="email"
            id="username"
            name="username"
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setUser(e.target.value)}
            value={user}
            required
          />
          <label htmlFor="username">Email:</label>
        </InputWrapper>
        <Button variation="alt" loading={loading}>
          Recover Password
        </Button>
      </Form>
      <p>
        Go back to <Link to="/sign-in">Sign In</Link>
      </p>
    </HomeLayout>
  );
};

export default ForgotPassword;
