import { useEffect, useState } from 'react';
import styles from './response.module.scss';
import useThread from '@/pages/Thread/hooks/useThread';
import useBlock from '@/pages/Thread/hooks/useBlock';
import HTMLContent from '../HTMLContent';
import CitationIndicator from '../../CitationIndicator';
import CitationModal from '../CitationsModal';
import useTypingEffect from '@/pages/Thread/hooks/useTypingEffect';

const Response = () => {
  const { status } = useThread();
  const { block, isLast } = useBlock();
  const { response, sources } = block;
  const [selectedChunk, setSelectedChunk] = useState<number | null>(null);
  const isTyping = isLast && status === 'TYPING';
  const { chunks, setChunks } = useTypingEffect(isTyping, response);

  useEffect(() => {
    if (status === 'READY' || status === 'CITING') {
      setChunks(response);
    }
  }, [response, setChunks, status]);

  useEffect(() => {
    if (isTyping && chunks.length === 0 && response.length > 0) {
      setChunks([
        {
          text: '',
          citation: response[0].citation,
        },
      ]);
    }
  }, [chunks.length, isTyping, response, setChunks]);

  return (
    <div
      className={styles['response']}
      data-typing={isLast && status === 'TYPING'}
    >
      {chunks.length === 0 && <p className={styles['response-part']}></p>}
      {chunks.map(({ text, citation }, i) => {
        return (
          <p className={styles['response-part']} key={i}>
            <HTMLContent text={text} />
            {isTyping && i === chunks.length ? null : (
              <>
                {citation?.map((citation, j) => (
                  <CitationIndicator
                    key={j}
                    id={citation.source_id}
                    onClick={() => {
                      setSelectedChunk(i);
                    }}
                  />
                ))}
                {citation?.length > 0 && <br />}
              </>
            )}
          </p>
        );
      })}

      {selectedChunk !== null && (
        <CitationModal
          chunk={chunks[selectedChunk]}
          sources={sources}
          onClose={() => setSelectedChunk(null)}
        />
      )}
    </div>
  );
};

export default Response;
