import { AWS_LLM_API } from '@/config/aws';
import { APIThread, MessageChunk, Thread } from '@/models/thread';
import { extractPayload, getSubHeader } from '@/utils/api';
import { convertTimestampToString, parseFullMessage } from '@/utils/functions';
import { get } from 'aws-amplify/api';

interface ThreadsPayload {
  data: {
    threadData: APIThread[];
    paginationParams: {
      totalPages: number;
      totalThreadCount: number;
      visibleThreadCount: number;
    };
  };
}

const fetchThreads = async (
  userSub: string
): Promise<{ threads: Thread[]; hiddenThreadsCount: number }> => {
  const fetchPage = async (
    page = 1
  ): Promise<{
    threads: Thread[];
    totalPages: number;
    hiddenThreadsCount: number;
  }> => {
    const pageThreads: Thread[] = [];
    const { response } = get({
      apiName: AWS_LLM_API,
      path: '/v1/base/conversation/threads',
      options: {
        headers: getSubHeader(userSub),
        queryParams: {
          page: page.toString(),
          perPage: '100',
        },
      },
    });
    const payload = await extractPayload<ThreadsPayload>(response);

    for (const thread of payload.data.threadData) {
      pageThreads.push({
        id: thread.thread_id,
        title: thread.title,
        blocks: thread.thread_items.map((block) => {
          let enabledCitation = false;
          let response: MessageChunk[] = [];
          if (typeof block.citations !== 'undefined') {
            const citedResponse = block.citations.response;

            if (citedResponse?.length > 0) {
              response = citedResponse as MessageChunk[];
            } else {
              enabledCitation = true;
            }
          }

          if (response.length === 0) {
            response = parseFullMessage(block.llm_response);
          }

          return {
            id: block.message_id,
            disliked: false,
            prompt: block.user_input,
            queryDate: new Date(block.query_ts),
            response,
            enabledCitation: enabledCitation,
            sources: block.rag_sources,
          };
        }),
        filters: {
          countries: thread.filters.countries,
          sources: thread.filters.source_category,
          earliestDate: convertTimestampToString(
            thread.filters.publish_date.earliest
          ),
          latestDate: convertTimestampToString(
            thread.filters.publish_date.latest
          ),
        },
      });
    }

    return {
      threads: pageThreads,
      totalPages: payload.data.paginationParams.totalPages,
      hiddenThreadsCount:
        payload.data.paginationParams.totalThreadCount -
        payload.data.paginationParams.visibleThreadCount,
    };
  };
  const { threads, totalPages, hiddenThreadsCount } = await fetchPage();

  if (totalPages > 1) {
    for (let page = 2; page <= totalPages; page++) {
      const { threads: pageThreads } = await fetchPage(page);

      if (pageThreads.length === 0) {
        break;
      } else {
        threads.push(...pageThreads);
      }
    }
  }

  return { threads, hiddenThreadsCount };
};

export default fetchThreads;
