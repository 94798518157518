import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import useSession from '@/hooks/useSession';
import styles from './root-layout.module.scss';
import { createUserAuth } from '@/utils/session';
import { classnames } from '@/utils/functions';
import getSession from '@/api/users/getSession';
import ClarityScript from '@/components/ClarityScript';
import { gaIdentifyUser } from '@/utils/analytics';
import LoadingSpinner from '@/components/LoadingSpinner';
import { Slide, toast, ToastContainer } from 'react-toastify';

const RootLayout = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { session, setSession } = useSession();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const publicURLs = useRef<string[]>([
    '/',
    '/sign-up',
    '/pricing',
    '/privacy',
    '/terms-of-use',
    '/status',
    '/confirmUser',
  ]);

  useEffect(() => {
    if (pathname === '/sign-in') {
      setIsLoading(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (publicURLs.current.includes(pathname)) {
      setIsLoading(false);
      return;
    } else if (import.meta.env.VITE_MAINTENANCE_END) {
      navigate('/status', { replace: true });
      return;
    } else if (session && session.email) {
      setIsLoading(false);
      return;
    } else {
      const checkIfLoggedIn = async () => {
        try {
          const newSession = await getSession();

          if (!newSession) {
            setIsLoading(false);
          } else {
            const { email, sub } = newSession;

            const tmpAuth = await createUserAuth(email, sub);
            setSession(tmpAuth);
            gaIdentifyUser(tmpAuth.sub);

            if (tmpAuth.pendingPolicies.length > 0) {
              navigate('/policy-review');
            } else if (pathname === '/sign-in') {
              navigate('/', { replace: true });
            }
            setIsLoading(false);
          }
        } catch (err) {
          console.error(err);
          setIsLoading(false);
        }
      };
      checkIfLoggedIn();
    }
  }, [session, navigate, pathname, setSession]);

  useEffect(() => {
    toast.dismiss();
  }, [pathname]);

  return (
    <main
      className={classnames(styles.root, { [styles['loading']]: isLoading })}
    >
      <ClarityScript />
      {isLoading ? (
        <div className={styles['loading-wrapper']}>
          <img
            className={styles['loading-logo']}
            src="/gannet-green.png"
            height="150"
            alt="Gannet green logo"
          />
          <LoadingSpinner />
        </div>
      ) : (
        <Outlet />
      )}
      <ToastContainer
        className={styles['toasty']}
        position="bottom-right"
        pauseOnHover={true}
        autoClose={4000}
        transition={Slide}
      />
    </main>
  );
};

export default RootLayout;
